import styled from '@emotion/styled';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { LoadableButton } from '@components';

export let StyledLink = styled(Link)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    text-decoration: underline;
    color: var(--color-text-primary-2);
  }
`;

export let StyledButtonLink = styled(LoadableButton)`
  margin: 0;
  padding: 0 !important;
  color: var(--color-elements-primary);
`;

export let StyledDocumentLink = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
  text-decoration: underline;
  color: var(--color-text-primary-2);
  cursor: pointer;
`;
