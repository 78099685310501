import styled from '@emotion/styled';
import { Typography } from 'antd';
import { LoadableButton } from '@components';

export let StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
`;

export let StyledTitle = styled(Typography.Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  color: var(--color-text-primary-1);
`;

export let StyledButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
`;

export let StyledLoadableButton = styled(LoadableButton)`
  &&& {
    border-radius: 6px;

    span {
      font-size: 12px;
      line-height: 1;
    }
  }
`;
