export enum MenuItem {
  USERS = 'USERS',
  CLIENTS = 'CLIENTS',
  ACCOUNTS = 'ACCOUNTS',
  ACCOUNT_PROFILE = 'ACCOUNT_PROFILE',
  TRANSACTIONS = 'TRANSACTIONS',
  TRANSACTION_PROFILE = 'TRANSACTION_PROFILE',
  SETTINGS = 'SETTINGS',
  SETTINGS_INVITES = 'SETTINGS.INVITES',
  SETTINGS_ROLES = 'SETTINGS.ROLES',
  SETTINGS_USERS = 'SETTINGS.USERS',
}
