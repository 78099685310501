import { getColorsByTwoLettersConfig } from './getColorsByTwoLettersConfig';

let bgOneColors = [
  'var(--color-elements-additional-5)',
  'var(--color-elements-additional-7)',
  'var(--color-elements-additional-9)',
  'var(--color-elements-additional-11)',
  'var(--color-elements-additional-13)',
  'var(--color-elements-additional-15)',
  'var(--color-elements-additional-17)',
  'var(--color-elements-additional-19)',
];

let initialColors = [
  'var(--color-elements-additional-4)',
  'var(--color-elements-additional-6)',
  'var(--color-elements-additional-8)',
  'var(--color-elements-additional-10)',
  'var(--color-elements-additional-12)',
  'var(--color-elements-additional-14)',
  'var(--color-status-warning-2)',
  'var(--color-status-warning-2)',
];

let { getIndexByLetters } = getColorsByTwoLettersConfig(
  'A ',
  'Z ',
  initialColors,
);

export function getAvatarColorsByLetter(letters: string) {
  let index = getIndexByLetters(letters.concat(' '));

  return {
    backgroundColor: bgOneColors?.[index],
    initialColor: initialColors?.[index],
  };
}
