import { Tabs } from 'antd';
import styled from '@emotion/styled';

export let StyledTabs = styled(Tabs)`
  &&& {
    font-size: 14px;
    line-height: 1.71;
    color: var(--color-elements-additional-2);

    .ant-tabs-nav {
      margin: 0 0 12px 0;
    }

    .ant-tabs-tab {
      padding: 6px 0;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--color-elements-additional-3);
    }

    .ant-tabs-tab-active .anticon {
      color: var(--color-elements-additional-3);
    }

    .ant-tabs-ink-bar {
      background-color: var(--color-elements-additional-3);
    }
  }
`;
