import { Button } from 'antd';
import styled from '@emotion/styled';

export let StyledPrimaryButton = styled(Button)`
  &&& {
    height: 100%;
    border-color: transparent;

    &:not(&:disabled) {
      &:hover {
        opacity: 0.8;
      }

      background-color: var(--color-button-primary);
      color: var(--color-button-primary-text-icon);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled {
      span {
        color: var(--color-button-disable-text);
      }
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.375;
      color: var(--color-button-primary-text-icon);
    }
  }
`;

export let StyledPositiveButton = styled(Button)`
  &&& {
    height: 100%;
    border-color: transparent;

    &:not(&:disabled) {
      &:hover {
        opacity: 0.8;
      }

      background-color: var(--color-button-success);
      color: var(--color-text-success);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled {
      span {
        color: var(--color-button-disable);
      }
    }

    span {
      font-weight: 400;
      font-size: 12px;
      color: var(--color-text-success);
    }
  }
`;

export let StyledOutlineButton = styled(Button)`
  &&& {
    height: 100%;
    border-color: transparent;

    &:not(&:disabled) {
      &:hover {
        opacity: 0.8;
      }

      border-color: var(--color-button-primary-stroke);
      color: var(--color-text-primary);
    }

    &:disabled {
      cursor: not-allowed;
      // For icons
      * {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    span {
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

export let StyledDangerButton = styled(Button)`
  &&& {
    height: 100%;
    border-color: transparent;

    &:not(&:disabled) {
      &:hover {
        opacity: 0.8;
      }

      background-color: var(--color-button-error);
      border: 1px solid var(--color-button-error);
      color: var(--color-button-error-text);
    }

    &:disabled {
      cursor: not-allowed;
    }

    span {
      font-weight: 400;
      font-size: 12px;
    }
  }
`;
