import styled from '@emotion/styled';
import { Typography } from 'antd';

export let StyledNameWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
`;

export let StyledFieldWrapper = styled.div`
  display: grid;
  grid-row-gap: 4px;
`;

export let StyledTitle = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  color: var(--color-field-title);
`;
