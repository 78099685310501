export function convertSnackToCamel(str: string) {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    );
}

export function convertCamelToSnakeCase(str?: string) {
  return str?.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function convertToSnakeCase(str?: string) {
  return str
    ?.replace(/\s+/g, '_')
    .replace(/[^a-zA-Z0-9_]/g, '')
    .toLowerCase();
}
