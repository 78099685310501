import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createCustomShouldForwardProp } from '@functions';

export interface ITextStyleProps {
  color?: string;
  size?: 12 | 14 | 16 | 18 | 20 | 22 | 24 | 38;
  lineHeight?: 12 | 14 | 16 | 18 | 20 | 22 | 24 | 28 | 32 | 52;
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  transform?: 'capitalize' | 'uppercase' | 'lowercase';
  align?: 'left' | 'center' | 'right';
  wrap?: boolean;
  truncate?: boolean;
  className?: string;
}

const RESETS = `
  margin: 0;
  padding: 0;
  letter-spacing: 0.4px;
`;

const excludeForwardProps = [
  'color',
  'size',
  'lineHeight',
  'weight',
  'transform',
  'align',
  'wrap',
];

let shouldForwardProp = createCustomShouldForwardProp(excludeForwardProps);

export const StyledText = styled('p', { shouldForwardProp })<ITextStyleProps>`
  ${RESETS};
  color: ${({ color }) => color};
  font-size: ${({ size }) => size}px;
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  font-weight: ${({ weight }) => weight};
  ${({ transform }) => transform && `text-transform: ${transform};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ wrap }) => wrap && `word-break: break-word;`}
  ${({ truncate }) =>
    truncate &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;
