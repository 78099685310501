import styled from '@emotion/styled';
import { Modal } from 'antd';

export let StyledModal = styled(Modal)`
  &&& {
    .ant-modal-content {
      padding: 0;
    }

    .ant-modal-header {
      margin-bottom: 0;
      padding: 16px 20px;
      background-color: var(--color-background-additional-dark);
      box-shadow: inset 0px -1px 0px var(--color-background-additional-main);
      border-radius: 6px 6px 0px 0px;
    }

    .ant-modal-body {
      padding: 20px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      color: var(--color-text-primary-1);
    }

    .ant-modal-footer {
      display: flex;
      margin-top: 0;
      padding: 10px 24px;
      background-color: var(--color-background-additional-light);
      box-shadow: inset 0 1px 0 var(--color-background-additional-main);
      border-radius: 0px 0px 6px 6px;
      & button {
        flex: 1;
      }
    }

    .ant-modal-close-icon {
      color: var(--color-modal-close);
    }
  }
`;
