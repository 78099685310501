import styled from '@emotion/styled';

export let StyledWrapper = styled.div`
  &&& {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 8px;
  }
`;

export let StyledDisplayName = styled.span`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    color: var(--color-text-primary);
  }
`;
