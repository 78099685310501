import { insertIfObj } from './insertIf';

export function getSelectSearchProps(withSort: boolean = false) {
  return {
    showSearch: true,
    optionFilterProp: 'children',
    filterOption: (input: string, option: any) => {
      if (typeof option.children === 'string') {
        return (
          option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        );
      }

      return true;
    },
    ...insertIfObj(withSort, {
      filterSort: (optionA: any, optionB: any) =>
        optionA?.children
          ?.toLowerCase()
          ?.localeCompare(optionB?.children?.toLowerCase()),
    }),
  };
}
