import { IBaseApiRequest } from '@api';
import { IPaginated, IRole, ISortable, PaginationMeta } from '@entities';
import { getSorterParamsForApi, insertIfObj } from '@functions';
import { AxiosResponse } from 'axios';

interface IGetAll extends IBaseApiRequest, IPaginated, ISortable {
  baasClientId: string;
  search: string;
}
export async function getAll({
  api,
  offset,
  limit,
  attribute,
  direction,
  search,
  baasClientId,
}: IGetAll) {
  let params = {
    offset,
    limit,
    ...insertIfObj(Boolean(search), {
      search,
    }),
    ...getSorterParamsForApi({ attribute, direction }),
  };

  let response = (await api.get(`platform/${baasClientId}/roles`, {
    params,
  })) as AxiosResponse<{
    data: {
      data: IRole[];
      meta: PaginationMeta;
    };
  }>;

  return response.data.data;
}
