import { ReactNode, useMemo } from 'react';
import { InputProps } from 'antd';
import {
  StyledAlert,
  StyledFormItem,
  StyledInput,
  StyledNumberInput,
  StyledPasswordInput,
} from './index.styles';

export interface IInputProps extends InputProps {
  title?: string;
  error?: string;
  password?: boolean;
  withFormItem?: boolean;
  customNode?: ReactNode;
  isCurrency?: boolean;
}

export function Input({
  error,
  password,
  title,
  withFormItem,
  customNode,
  isCurrency,
  ...props
}: IInputProps) {
  let renderedInput = useMemo(() => {
    if (isCurrency) {
      return (
        <StyledNumberInput
          status={error ? 'error' : undefined}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
          onChange={(value) => props.onChange && props.onChange(value as any)}
          name={props.name}
          placeholder={props.placeholder}
          prefix={props.prefix}
          addonAfter={props.addonAfter}
          precision={2}
          min={0}
        />
      );
    }

    if (!password) {
      return (
        <StyledInput
          {...props}
          status={error ? 'error' : undefined}
          isEmpty={!Boolean(props.value)}
        />
      );
    }

    if (password) {
      return (
        <StyledPasswordInput {...props} status={error ? 'error' : undefined} />
      );
    }
  }, [error, isCurrency, password, props]);

  if (withFormItem) {
    return (
      <StyledFormItem
        label={title}
        validateStatus={error ? 'error' : undefined}
        help={error}
      >
        {customNode || renderedInput}
      </StyledFormItem>
    );
  }

  return (
    <>
      {customNode || renderedInput}
      {error && <StyledAlert message={error} type="error" showIcon={false} />}
    </>
  );
}
