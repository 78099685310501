import { AxiosResponse } from 'axios';
import {
  IPaginated,
  ISortable,
  IBapiUser,
  IBapiUsersFilters,
  PaginationMeta,
} from '@entities';
import { IBaseApiRequest, IFilterByCustomer } from '@api';
import { getSorterParamsForApi, insertIfObj } from '@functions';

interface IGetAll
  extends IBaseApiRequest,
    IPaginated,
    ISortable,
    IBapiUsersFilters,
    IFilterByCustomer {}
export async function getAll({
  api,
  offset,
  limit,
  attribute,
  direction,
  search,
  country,
  status,
  baasClientId,
}: IGetAll) {
  let params = {
    offset,
    limit,
    ...insertIfObj(Boolean(status), {
      state: status,
    }),
    ...insertIfObj(Boolean(country), {
      country_id: country,
    }),
    ...insertIfObj(Boolean(search), {
      search,
    }),
    ...getSorterParamsForApi({ attribute, direction }),
  };

  let response = (await api.get(`bank/${baasClientId}/users`, {
    params,
  })) as AxiosResponse<{
    data: {
      data: IBapiUser[];
      meta: PaginationMeta;
    };
  }>;

  return response.data.data;
}

interface IGetOne extends IBaseApiRequest, IFilterByCustomer {
  id: string;
}
export async function getOne({ api, id, baasClientId }: IGetOne) {
  let response = (await api.get(
    `bank/${baasClientId}/users/${id}`,
  )) as AxiosResponse<{
    data: IBapiUser;
  }>;

  return response.data.data;
}

interface ICreate extends IBaseApiRequest, IFilterByCustomer {
  dto: {
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
  };
}
export async function create({ api, dto, baasClientId }: ICreate) {
  try {
    let response = (await api.post(
      `bank/${baasClientId}/users`,
      dto,
    )) as AxiosResponse<{
      data: IBapiUser;
    }>;

    return {
      success: true,
      result: {},
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.message,
    };
  }
}

interface IUpdate extends IBaseApiRequest, IFilterByCustomer {
  id: string;
  dto: {
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
  };
}
export async function update({ api, id, dto, baasClientId }: IUpdate) {
  try {
    let response = (await api.patch(
      `/bank/${baasClientId}users/${id}`,
      dto,
    )) as AxiosResponse<{
      data: IBapiUser;
    }>;

    return {
      success: true,
      result: {},
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.message,
    };
  }
}
