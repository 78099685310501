import { Select as UISelect, SelectProps } from 'antd';
import { getSelectSearchProps } from '@functions';
import { StyledSelect } from './index.styles';

export function Select(props: SelectProps) {
  return (
    // @ts-ignore
    <StyledSelect
      {...getSelectSearchProps()}
      getPopupContainer={(trigger) => trigger.parentElement}
      {...props}
    />
  );
}

export let SelectOption = UISelect.Option;
export let SelectOptGroup = UISelect.OptGroup;
