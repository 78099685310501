import { IBaseApiRequest, IFilterByCustomer } from '@api';
import { IAccount, IPaginated, ISortable, PaginationMeta } from '@entities';
import { AxiosResponse } from 'axios';
import { DateFormat, getSorterParamsForApi, insertIfObj } from '@functions';
import dayjs from 'dayjs';

interface IGetAll
  extends IPaginated,
    IBaseApiRequest,
    ISortable,
    IFilterByCustomer {
  search?: string;
  status?: string | null;
  bankPartner?: string | undefined | null;
  kinds?: string[];
  kind?: string;
  bcaKinds?: string[];
  currencies?: string[];
  clientId?: string;
  dateRange?: { from: number | undefined; to: number | undefined };
  withoutDeleted?: boolean;
  serviceAccountCode?: string;
  widgetSearch?: string;
}
export async function getAll({
  api,
  search,
  status,
  bankPartner,
  kinds,
  kind,
  bcaKinds,
  currencies,
  dateRange,
  offset,
  limit,
  clientId,
  withoutDeleted,
  serviceAccountCode,
  widgetSearch,
  attribute,
  direction,
  baasClientId,
}: IGetAll) {
  let params = {
    ...insertIfObj(Boolean(search), {
      search,
    }),
    ...insertIfObj(Boolean(widgetSearch), {
      'q[client_account_account_no_or_virtual_account_account_no_or_service_account_account_no_eq]':
        widgetSearch,
    }),
    ...insertIfObj(Boolean(dateRange?.from), {
      'q[created_at_gteq]': dayjs
        .unix(Number(dateRange?.from))
        .format(DateFormat.DATE_FILTER),
    }),
    ...insertIfObj(Boolean(dateRange?.to), {
      'q[created_at_lteq]': dayjs
        .unix(Number(dateRange?.to))
        .format(DateFormat.DATE_FILTER),
    }),
    ...insertIfObj(Boolean(currencies?.length), {
      'q[currency_id_in]': currencies?.join(','),
    }),
    ...insertIfObj(Boolean(status), {
      state: status,
    }),
    ...insertIfObj(Boolean(bankPartner), {
      bank_entity_id: bankPartner,
    }),
    ...insertIfObj(Boolean(kinds?.length), {
      'q[kind_in]': kinds?.join(','),
    }),
    ...insertIfObj(Boolean(kind), {
      'q[kind_eq]': kind,
    }),
    ...insertIfObj(Boolean(bcaKinds?.length), {
      bcaKinds: bcaKinds?.join(','),
    }),
    ...insertIfObj(Boolean(clientId), {
      clientId,
    }),
    ...insertIfObj(Boolean(withoutDeleted), {
      'q[client_account_is_deleted_false]': true,
    }),
    ...insertIfObj(Boolean(serviceAccountCode), {
      'q[service_account_internal_service_code_eq]': serviceAccountCode,
    }),
    offset,
    limit,
    ...getSorterParamsForApi({ attribute, direction }),
  };

  try {
    let response = (await api.get(`bank/${baasClientId}/client_accounts`, {
      params,
    })) as AxiosResponse<{
      data: {
        data: IAccount[];
        meta: PaginationMeta;
      };
    }>;

    return response.data.data;
  } catch (error) {}
}

interface IGetOne extends IBaseApiRequest, IFilterByCustomer {
  id: string;
}
export async function getOne({ api, id, baasClientId }: IGetOne) {
  try {
    let response = (await api.get(
      `bank/${baasClientId}/client_accounts/${id}`,
    )) as AxiosResponse<{
      data: IAccount;
    }>;

    return response.data.data;
  } catch (error) {}
}
