function calculateCharCodeSum(letters: string) {
  return letters.charCodeAt(0) + letters.charCodeAt(1);
}

function getSafetyIndex(from: number, current: number, to: number) {
  return Math.max(from, Math.min(current, to));
}

function getIndexBySum(
  from: number,
  to: number,
  sum: number,
  recipientAvatarK: number,
) {
  return getSafetyIndex(from, Math.floor(sum / recipientAvatarK), to);
}

function calculateCharCodeSumWithOffset(letters: string, offset: number) {
  return calculateCharCodeSum(letters) - offset;
}

export function getColorsByTwoLettersConfig(
  from: string,
  to: string,
  colors: string[],
) {
  let minSum = calculateCharCodeSum(from);
  let maxSum = calculateCharCodeSum(to);

  let offset = minSum;
  let distance = maxSum - minSum;

  let recipientAvatarK = distance / (colors.length - 1);

  function getIndexByLetters(letters: string) {
    return getIndexBySum(
      0,
      colors.length - 1,
      calculateCharCodeSumWithOffset(letters, offset),
      recipientAvatarK,
    );
  }

  function getColorByLetters(letters: string) {
    return colors?.[getIndexByLetters(letters)];
  }
  return {
    getColorByLetters,
    getIndexByLetters,
  };
}
