import React, { PropsWithChildren, useMemo } from 'react';
import { AxiosError } from 'axios';
import { api } from '@api';
import { createAxiosClient } from '../../api/client';
import { navigateToLogin } from './navigateToLogin';

export let AxiosClientContext = React.createContext({
  client: createAxiosClient(''),
  api,
});

export function AxiosClientProvider({ children }: PropsWithChildren<unknown>) {
  let client = useMemo(() => {
    let client = createAxiosClient(import.meta.env.VITE_API_URL);

    client.interceptors.response.use(undefined, (error: AxiosError) => {
      if (
        error?.response?.status === 301 ||
        error?.response?.status === 302 ||
        error?.response?.status === 401 ||
        // Remove after cors will be resolved
        error?.response?.status === 0
      ) {
        return navigateToLogin();
      }

      return Promise.reject(error);
    });

    return client;
  }, []);

  return (
    <AxiosClientContext.Provider
      value={{
        client,
        api,
      }}
    >
      {children}
    </AxiosClientContext.Provider>
  );
}
