export enum InviteState {
  CREATED = 'new',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export interface IInvitesFilters {
  search: string;
  status: string | null;
}

export interface IInvite {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  state: string;
  inviteUrl: string;
  baasRoleId: string;
  aidInviteId: string;
  expiresAt: string;
  createdAt: string;
  updatedAt: string;
  may: {
    revoke: boolean;
    resend: boolean;
  };
  role: {
    id: string;
    displayName: string;
  };
}
