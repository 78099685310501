import { IDirectoryItem } from './DirectoryItem';

export enum ClientDraftState {
  APPROVED = 'approved',
  IN_REVIEW = 'in_review',
  CREATED = 'created',
  FORM_REQUESTED = 'form_requested',
  SIGNED = 'signed',
  CLIENT_CREATED = 'client_created',
  PRE_ACTIVATED = 'pre_activated',
  REJECTED = 'rejected',
  WAITED = 'waited',
  ARCHIVED = 'archived',
}

export interface IClientAidExtendedData {
  onboardingFirstName: string;
  onboardingLastName: string;
  onboardingRoleInCompany: string;

  onboardingCompanyName: string;
  onboardingDbaName: string;
  onboardingCompanyWebsite: string;
  arivalCompanyMainActivities: string;

  arivalLocationAddress: string;
  arivalLocationHomeNumber: string;
  arivalLocationCity: string;
  arivalLocationState: string;
  arivalLocationZip: string;
  arivalLocationCountry: string;

  arivalCompanyWorkAddress: string;
  arivalCompanyWorkAddressCity: string;
  arivalCompanyWorkAddressState: string;
  arivalCompanyWorkAddressZip: string;
  arivalCompanyAddressCountry: string;
  arivalCompanyWorkAddressCountry: string;

  arivalHowGenerateIncome: string;
  arivalHowAccountUsed: string;
  arivalYouCustomers: string;
  arivalPrimaryPurposeOpeningAccount: string;
  arivalWhatServicesUse: string;

  arivalExpectedNumberIncomingTransactionMonth: string;
  arivalExpectedMonthlyDeposit: string;
  arivalCountriesWhereExpectReceiveFunds: string;

  arivalExpectedNumberOutgoingTransactionMonth: string;
  arivalExpectedOutgoingMonthly: string;
  arivalCountriesWhereExpectSendFunds: string;

  arivalWhichBestDescribesSourceFundingCompany: string;
  arivalWhereFirstDepositFrom: string;

  arivalEntityHolderDob: string;
  arivalEntityHolderEmailAddress: string;
  arivalEntityHolderPhoneNumber: string;
  arivalEntitySubtype: string;
}

export interface IClientDraft {
  id: string;
  aidCase: {
    lastSubmissionCompletedAt: number | null;
    score: string;
  };
  aidCaseUrl: string;
  approvedAt: number | null;
  bankEntity: {
    id: string;
    displayName: string;
  };
  client: {
    id: string;
    displayName: string;
  };
  companyRegistrationNumber: string | null;
  complianceTags: Array<{
    displayName: string;
    id: string;
  }>;
  country: IDirectoryItem;
  createdAt: number | null;
  displayName: string;
  bcaState: ClientDraftState;
  dob: string | null;
  entityName: string | null;
  extendedData: IClientAidExtendedData | null;

  firstName: string;
  kind: string;
  lastName: string;
  may: {
    approve: boolean;
    reject: boolean;
    delete: boolean;
    addAidVerification: boolean;
  };
  onboardingFacts: {};
  primaryCurrency: IDirectoryItem;
  rejectionReason: string | null;
  signedAt: number | null;
  specialActivities: {};
  state: ClientDraftState;
  submittedAt: number | null;
  tariff?: {
    id: string;
    displayName: string;
    tariffGroup: {
      id: string;
      displayName: string;
    };
  };
  updatedAt: number | null;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
}
