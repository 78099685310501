import styled from '@emotion/styled';
import { Typography } from 'antd';

interface IGridStyledProps {
  columns: number;
}

export let TitleStyled = styled(Typography.Text)`
  &&& {
    display: block;
    width: 100%;
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.83;
    color: var(--color-text-main-elements);
    background-color: var(--color-background-additional-main);
  }
`;

export let GridStyled = styled.div<IGridStyledProps>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns && `repeat(${columns}, 1fr)`};
  grid-column-gap: 40px;
  grid-row-gap: 8px;
  background-color: var(--color-background-primary);
  padding: 4px 16px;

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
  }
`;

export let FlexStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-right: 48px;
  padding: 4px 16px;
  width: 100%;
  background-color: var(--color-background-additional-2);

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
  }
`;

export let FieldStyled = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    color: var(--color-text-additional);
    display: flex;
    align-items: start;
    height: fit-content;
  }
`;

export let FieldValueStyled = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    text-align: right;
    color: var(--color-text-primary);
    align-items: start;
    display: inline-flex;
    justify-content: flex-end;
    word-break: break-all;
  }
`;
