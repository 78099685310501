import styled from '@emotion/styled';
import { LoadableButton } from '@components';

export let Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  min-height: 48px;
  padding: 4px 12px;
  background-color: var(--color-background-additional-main);
  border-left: 1px solid var(--color-background-additional-main);
  border-right: 1px solid var(--color-background-additional-main);
  border-bottom: 1px solid var(--color-background-additional-main);
  border-radius: 0 0 6px 6px;
`;

export let ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  max-width: 900px;
`;

export let StyledLoadableButton = styled(LoadableButton)`
  margin: 4px;
  min-width: 180px;
`;
