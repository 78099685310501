import { useMemo } from 'react';
import { ColProps } from 'antd';
import { useWindowSize } from '@hooks';
import { StyledCol } from './index.styles';

export function Col(props: ColProps) {
  let { isTablet } = useWindowSize();

  let offset = useMemo(() => {
    if (!isTablet && props.offset) {
      return props.offset;
    }
  }, [isTablet, props.offset]);

  return <StyledCol {...props} offset={offset} />;
}
