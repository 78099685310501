import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import {
  StyledDatePicker,
  StyledWrapper,
  StyledPicker,
  StyledRangeDivider,
} from './index.styles';

dayjs.extend(weekday);
dayjs.extend(localeData);

type CustomDateRange = {
  from: number | undefined;
  to: number | undefined;
};
interface IDateFilterProps {
  className?: string;
  onChange?: (dateRange: CustomDateRange) => void;
  value?: CustomDateRange;
  showTime?: {
    format: string;
  };
  format?: string;
  disabled?: boolean;
}
function DateRangeFilter({
  className,
  onChange,
  value,
  showTime,
  format,
  disabled,
}: IDateFilterProps) {
  let hasValue = Boolean(value?.from) || Boolean(value?.to);
  return (
    <StyledWrapper hasValue={hasValue} className={className}>
      <StyledPicker
        placeholder="From date"
        hasValue={hasValue}
        format={format}
        showTime={showTime}
        disabled={disabled}
        onChange={(newValue) =>
          onChange &&
          onChange({
            from: newValue?.startOf('day').unix(),
            to: value?.to,
          })
        }
        disabledDate={(currentDate) =>
          currentDate && currentDate.isAfter(dayjs().endOf('day'))
        }
        value={Boolean(value?.from) ? dayjs.unix(Number(value?.from)) : null}
      />
      <StyledRangeDivider hasValue={hasValue} />
      <StyledPicker
        placeholder="To date"
        hasValue={hasValue}
        format={format}
        showTime={showTime}
        disabled={disabled}
        disabledDate={(currentDate) =>
          currentDate && currentDate.isAfter(dayjs().endOf('day'))
        }
        onChange={(newValue) =>
          onChange &&
          onChange({
            from: value?.from,
            to: newValue?.endOf('day').unix(),
          })
        }
        value={Boolean(value?.to) ? dayjs.unix(Number(value?.to)) : null}
      />
    </StyledWrapper>
  );

  // return (
  //   <StyledRangePicker
  //     className={className}
  //     onChange={(dateRange) => {
  //       onChange &&
  //         onChange({
  //           from: dateRange?.[0]?.startOf('day').unix(),
  //           to: dateRange?.[1]?.endOf('day').unix(),
  //         });
  //     }}
  //     value={[
  //       Boolean(value?.from) ? dayjs.unix(Number(value?.from)) : null,
  //       Boolean(value?.to) ? dayjs.unix(Number(value?.to)) : null,
  //     ]}
  //     disabledDate={(currentDate) =>
  //       currentDate && currentDate.isAfter(dayjs().endOf('day'))
  //     }
  //     showTime={showTime}
  //     format={format}
  //     hasValue={Boolean(value?.from) || Boolean(value?.to)}
  //   />
  // );
}

export let DateFilter = {
  RangePicker: DateRangeFilter,
  DatePicker: StyledDatePicker,
};
