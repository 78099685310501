import useSWR from 'swr';
import { useApi } from './useApi';

export function usePing() {
  let { api, client } = useApi();
  let {
    data,
    error,
    isValidating: isLoading,
  } = useSWR('auth/ping', async () =>
    api.baseApi.auth.ping({
      api: client,
    }),
  );

  return {
    data,
    error,
    isLoading,
  };
}
