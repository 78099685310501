import styled from '@emotion/styled';
import { Typography } from 'antd';

interface IStyledFieldColumnProps {
  wide?: boolean;
  order?: number;
}

export let StyledWrapper = styled.div`
  border: 1px solid var(--color-background-additional-3);
`;

export let StyledHeader = styled(Typography.Text)`
  &&& {
    display: block;
    width: 100%;
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.83;
    color: var(--color-text-primary-2);
    background-color: var(--color-background-additional-3);
  }
`;

export let StyledFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 8px;
  align-items: center;
  background-color: var(--color-background-primary);
  padding: 4px 16px;

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    justify-content: center;
  }
`;

export let StyledFieldColumn = styled.div<IStyledFieldColumnProps>`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 30px;
  align-items: center;
  width: 100%;

  @media (max-width: 1100px) {
    order: ${({ order }) => order};
  }
`;

export let WideFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-column: span 2;
  width: 100%;
`;

export let WideField = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr;
  grid-column-gap: 26px;
  align-items: center;
  grid-column: span 3;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 30px;
  }
`;

export let StyledField = styled(Typography.Text)`
  &&& {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-text-primary-2);

    & > span {
      margin-left: 4px;
      font-style: italic;
      font-weight: 400;
      font-size: 8px;
      line-height: 2.75;
      color: var(--color-text-additional-3);
    }
  }
`;
