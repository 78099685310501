import { AxiosResponse } from 'axios';
import { IClient, IPaginated, ISortable, PaginationMeta } from '@entities';
import { IBaseApiRequest, IFilterByCustomer } from '@api';
import { getSorterParamsForApi, insertIfObj } from '@functions';

interface IGetAll
  extends IPaginated,
    ISortable,
    IBaseApiRequest,
    IFilterByCustomer {
  search?: string;
  kind?: string | null;
  countries: string[];
  pricingPlans: string[];
  groups: string[];
  state?: string | null;
}
export async function getAll({
  api,
  search,
  kind,
  countries,
  pricingPlans,
  groups,
  offset,
  limit,
  attribute,
  direction,
  state,
  baasClientId,
}: IGetAll) {
  let params = {
    ...insertIfObj(Boolean(offset), { offset }),
    ...insertIfObj(Boolean(limit), { limit }),
    ...insertIfObj(Boolean(search), { search }),
    ...insertIfObj(Boolean(kind), { kind: kind }),
    ...insertIfObj(Boolean(state), { state: state }),
    ...insertIfObj(Boolean(countries.length), {
      'q[country_id_in]': countries.join(','),
    }),
    ...insertIfObj(Boolean(pricingPlans.length), {
      'q[tariff_id_in]': pricingPlans.join(','),
    }),
    ...insertIfObj(Boolean(groups.length), {
      complianceTagIds: groups.join(','),
    }),
    ...getSorterParamsForApi({ attribute, direction }),
  };

  let response = (await api.get(`bank/${baasClientId}/clients`, {
    params,
  })) as AxiosResponse<{
    data: {
      data: IClient[];
      meta: PaginationMeta;
    };
  }>;

  return response.data.data;
}

interface IGetOne extends IBaseApiRequest, IFilterByCustomer {
  id: string;
}
export async function getOne({ api, id, baasClientId }: IGetOne) {
  let response = (await api.get(
    `bank/${baasClientId}/clients/${id}`,
  )) as AxiosResponse<{
    data: IClient;
  }>;

  return response.data.data;
}
