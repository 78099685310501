export interface ILogoProps {
  className?: string;
  onClick?: () => void;
  width?: number;
  height?: number;
}

export function Logo({
  className,
  width = 26,
  height = 30,
  onClick,
}: ILogoProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M11.0591 20.2708C11.0299 20.2092 10.9841 20.1572 10.9269 20.1205C10.8697 20.0839 10.8034 20.0642 10.7356 20.0636H7.82373L9.02964 18.0514L11.2356 14.2044C11.2474 14.1537 11.2482 14.1011 11.2381 14.05C11.2279 13.999 11.207 13.9507 11.1768 13.9085C11.149 13.863 11.11 13.8255 11.0637 13.7996C11.0173 13.7737 10.9651 13.7602 10.912 13.7605H4.38246V11.1564H8.4708C8.62682 11.1564 8.77644 11.0941 8.88676 10.9831C8.99708 10.8721 9.05905 10.7216 9.05905 10.5646V7.54621C9.05905 7.38924 8.99708 7.2387 8.88676 7.12771C8.77644 7.01672 8.62682 6.95437 8.4708 6.95437H4.38246V4.35027H10.7356C10.8916 4.35027 11.0412 4.28792 11.1515 4.17692C11.2618 4.06593 11.3238 3.91539 11.3238 3.75843V0.592084C11.3238 0.435119 11.2618 0.284581 11.1515 0.17359C11.0412 0.0625981 10.8916 0.000244141 10.7356 0.000244141H0.58825C0.432237 0.000244141 0.282612 0.0625981 0.172294 0.17359C0.0619757 0.284581 0 0.435119 0 0.592084V17.4595C0 17.6165 0.0619757 17.767 0.172294 17.878C0.282612 17.989 0.432237 18.0514 0.58825 18.0514H4.61776L2.23535 22.5198C2.21787 22.5699 2.2141 22.6238 2.22445 22.6758C2.2348 22.7279 2.25889 22.7762 2.29418 22.8157C2.32196 22.8611 2.36091 22.8986 2.40727 22.9245C2.45363 22.9505 2.50585 22.9639 2.55889 22.9636H4.29423L1.8824 29.5331C1.85421 29.6085 1.85352 29.6915 1.88045 29.7673C1.90738 29.8432 1.96018 29.907 2.02946 29.9474C2.0933 29.9902 2.17062 30.0079 2.24659 29.997C2.32257 29.986 2.39188 29.9473 2.44124 29.8882L10.9709 20.6259C11.023 20.5852 11.0601 20.5281 11.0761 20.4637C11.0921 20.3993 11.0861 20.3313 11.0591 20.2708Z"
        fill="url(#paint0_linear_297_8564)"
      />
      <path
        d="M18.8527 13.7308L25.4999 0.858228C25.5443 0.769902 25.5659 0.6718 25.5628 0.572892C25.5598 0.473983 25.5321 0.377427 25.4824 0.292057C25.4326 0.206688 25.3624 0.13523 25.2781 0.0842187C25.1938 0.0332075 25.0982 0.00427372 24.9999 6.10352e-05H13.7938C13.6378 6.10352e-05 13.4881 0.062415 13.3778 0.173407C13.2675 0.284398 13.2055 0.434936 13.2055 0.591901V3.75825C13.2055 3.91521 13.2675 4.06575 13.3778 4.17674C13.4881 4.28773 13.6378 4.35009 13.7938 4.35009H18.8527L12.029 17.2137C11.9847 17.3021 11.963 17.4002 11.9661 17.4991C11.9692 17.598 11.9968 17.6945 12.0466 17.7799C12.0963 17.8653 12.1666 17.9367 12.2508 17.9877C12.3351 18.0388 12.4308 18.0677 12.529 18.0719H25.4117C25.5677 18.0719 25.7173 18.0095 25.8277 17.8986C25.938 17.7876 26 17.637 26 17.4801V14.3137C26 14.1568 25.938 14.0062 25.8277 13.8952C25.7173 13.7842 25.5677 13.7219 25.4117 13.7219L18.8527 13.7308Z"
        fill="#0B1C49"
      />
      <defs>
        <linearGradient
          id="paint0_linear_297_8564"
          x1="5.66191"
          y1="0.000244141"
          x2="5.66191"
          y2="30.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.576042" stopColor="#0B1C49" />
          <stop offset="0.727083" stopColor="#1C4FD6" />
          <stop offset="0.924408" stopColor="#00C2FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
