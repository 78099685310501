import dayjs from 'dayjs';

export enum DateFormat {
  DEFAULT = 'MM-DD-YYYY',
  EST_DEFAULT = 'MM-DD-YYYY HH:mm EST',
  EST_WITH_DOTS = 'MM.DD.YYYY HH:mm EST',
  WITH_TIME = 'MM-DD-YYYY HH:mm Z',
  DATE_FILTER = 'YYYY-MM-DD',
  DATE_FILTER_WITH_TIME = 'YYYY-MM-DD HH:mm:ss',
  DATE_FILTER_WITH_TIME_ET = 'YYYY-MM-DD HH:mm:ss -0500',
}

export function formatDate(unix: number = 0, withTime: boolean = true) {
  let format = withTime ? DateFormat.WITH_TIME : DateFormat.DEFAULT;

  return dayjs.unix(unix).format(format);
}
