import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { FullScreenSpin, ProtectedLayout } from '@components';
import { usePing } from '@hooks';
import { ROUTES } from './routes';

let SuspendedUsersPage = React.lazy(() => import('../pages/protected/Users'));
let SuspendedUserProfilePage = React.lazy(
  () => import('../pages/protected/UserProfile'),
);
let SuspendedAccountsPage = React.lazy(
  () => import('../pages/protected/Accounts'),
);
let SuspendedAccountProfilePage = React.lazy(
  () => import('../pages/protected/AccountProfile'),
);
let SuspendedTransactionsPage = React.lazy(
  () => import('../widgets/Transactions'),
);
let SuspendedTransactionProfilePage = React.lazy(
  () => import('../pages/protected/TransactionProfile'),
);
let SuspendedClientsPage = React.lazy(
  () => import('../pages/protected/Clients'),
);
let SuspendedClientProfilePage = React.lazy(
  () => import('../pages/protected/ClientProfile'),
);
let SuspendedInviteesPage = React.lazy(
  () => import('../pages/protected/Invites'),
);
let SuspendedInviteProfilePage = React.lazy(
  () => import('../pages/protected/InviteProfile'),
);

let SuspendedEzRolesPage = React.lazy(
  () => import('../pages/protected/EzRoles'),
);

let SuspendedEzUsersPage = React.lazy(
  () => import('../pages/protected/EzUsers'),
);

let SuspendedContactsPage = React.lazy(
  () => import('../pages/protected/ContactProfile'),
);

export function GlobalRouter() {
  let { data, error, isLoading } = usePing();

  if (isLoading) {
    return (
      <FullScreenSpin description="Please wait, we are checking authentication" />
    );
  }

  if (error || !data) {
    return (
      <FullScreenSpin description="Please wait, we will redirect you to the authentication service" />
    );
  }

  return (
    <ProtectedLayout>
      <Routes>
        <Route path={ROUTES.users} element={<SuspendedUsersPage />} />
        <Route
          path={ROUTES.userProfile}
          element={<SuspendedUserProfilePage />}
        />
        <Route path={ROUTES.accounts} element={<SuspendedAccountsPage />} />
        <Route
          path={ROUTES.accountProfile}
          element={<SuspendedAccountProfilePage />}
        />
        <Route
          path={ROUTES.transactions}
          element={<SuspendedTransactionsPage />}
        />
        <Route
          path={ROUTES.transactionProfile}
          element={<SuspendedTransactionProfilePage />}
        />
        <Route path={ROUTES.clients} element={<SuspendedClientsPage />} />
        <Route
          path={ROUTES.clientProfile}
          element={<SuspendedClientProfilePage />}
        />
        <Route path={ROUTES.ezInvites} element={<SuspendedInviteesPage />} />
        <Route path={ROUTES.ezRoles} element={<SuspendedEzRolesPage />} />
        <Route path={ROUTES.ezUsers} element={<SuspendedEzUsersPage />} />
        <Route
          path={ROUTES.contactProfile}
          element={<SuspendedContactsPage />}
        />
        <Route
          path={ROUTES.ezInviteProfile}
          element={<SuspendedInviteProfilePage />}
        />
        <Route path="*" element={<Navigate to={ROUTES.users} />} />
      </Routes>
    </ProtectedLayout>
  );
}
