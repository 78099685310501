import styled from '@emotion/styled';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

interface IStyledTextStyleProps {
  isEnabled: boolean;
}

export let Container = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0 4px 16px;
`;

export let StyledCheckIcon = styled(CheckOutlined)`
  margin-right: 5px;
  color: var(--color-status-success-2);
`;

export let StyledCloseIcon = styled(CloseOutlined)`
  margin-right: 5px;
  color: var(--color-status-error-2);
`;

export let StyledText = styled(Typography.Text)<IStyledTextStyleProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
  color: ${({ isEnabled }) =>
    isEnabled
      ? 'var(--color-status-success-2)'
      : 'var(--color-status-error-2)'};
`;
