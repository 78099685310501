import isPropValid from '@emotion/is-prop-valid';

export function shouldForwardProp(
  propName: string,
  excludeForwardProps: string[],
) {
  return isPropValid(propName) && !excludeForwardProps.includes(propName);
}

export function createCustomShouldForwardProp(excludeForwardProps: string[]) {
  return (propName: string) =>
    isPropValid(propName) && !excludeForwardProps.includes(propName);
}
