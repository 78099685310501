import { useEffect, useRef, useState, useCallback, ReactNode } from 'react';
import {
  BadgeWrapper,
  AvatarRoot,
  AvatarImage,
  Label,
  AvatarSizeT,
  BottomBadgeWrapper,
} from './index.styles';

export interface IAvatarProps {
  size?: AvatarSizeT;
  label?: string;
  src?: string;
  color?: string;
  icon?: ReactNode;
  badge?: ReactNode;
  bottomBadge?: ReactNode;
  backgroundColor?: string;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  className?: string;
  disabled?: boolean;
  onImageLoad?: () => void;
  onImageError?: () => void;
}

export function Avatar({
  size = 32,
  label,
  src,
  color,
  icon,
  badge,
  backgroundColor,
  top,
  left,
  right,
  bottom,
  className,
  disabled,
  bottomBadge,
  onImageLoad,
  onImageError,
}: IAvatarProps) {
  let imageRef = useRef<HTMLImageElement>(null);
  let [imageLoaded, setImageLoaded] = useState(false);

  let handleLoad = useCallback(() => {
    setImageLoaded(true);
    onImageLoad && onImageLoad();
  }, [onImageLoad]);

  let handleError = useCallback(() => {
    setImageLoaded(false);
    onImageError && onImageError();
  }, [onImageError]);

  useEffect(() => {
    setImageLoaded(false);
    let image = imageRef.current;

    if (image) {
      if (src) {
        image.src = src;
        image.onload = handleLoad;
        image.onerror = handleError;
      }
    }

    return () => {
      if (image) {
        image.onload = null;
        image.onerror = null;
      }
    };
  }, [src]);

  return (
    <AvatarRoot
      size={size}
      backgroundColor={backgroundColor}
      didImageFailToLoad={!imageLoaded}
      className={className}
      disabled={disabled}
    >
      <AvatarImage
        ref={imageRef}
        src={src}
        size={size}
        alt={src ? '' : 'Arival Bank International Corp.'}
        imageLoaded={imageLoaded}
      />
      {badge && (
        <BadgeWrapper style={{ top, left, right, bottom }}>
          {badge}
        </BadgeWrapper>
      )}
      {!imageLoaded && (
        <>
          {label && (
            <Label avatarSize={size} color={color} disabled={disabled}>
              {label}
            </Label>
          )}
          {icon}
          {bottomBadge && (
            <BottomBadgeWrapper>{bottomBadge}</BottomBadgeWrapper>
          )}
        </>
      )}
    </AvatarRoot>
  );
}
