import { InviteState } from '@entities';

export let inviteStatusMap = {
  [InviteState.CREATED]: 'Created',
  [InviteState.ACCEPTED]: 'Accepted',
  [InviteState.DECLINED]: 'Declined',
};

export let inviteStatusColorMap = {
  [InviteState.CREATED]: 'var(--color-status-default)',
  [InviteState.ACCEPTED]: 'var(--color-status-success)',
  [InviteState.DECLINED]: 'var(--color-status-error)',
};

export let inviteStatusFontColorMap = {
  [InviteState.CREATED]: 'var(--color-text-secondary)',
  [InviteState.ACCEPTED]: 'var(--color-text-success)',
  [InviteState.DECLINED]: 'var(--color-text-error)',
};

export function getInviteStatus(status: InviteState) {
  return {
    status: inviteStatusMap[status],
    color: inviteStatusColorMap[status],
    fontColor: inviteStatusFontColorMap[status],
  };
}
