import styled from '@emotion/styled';
import { Radio } from 'antd';

export let StyledRadio = styled(Radio)`
  &&& {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.83;
    color: var(--color-text-main-elements);

    .ant-radio-inner {
      border-color: var(--color-elements-secondary);
    }

    &:hover .ant-radio-inner {
      border-color: var(--color-elements-primary);
    }

    .ant-radio-checked .ant-radio-inner {
      background-color: var(--color-background-secondary);
      border-color: var(--color-elements-primary);

      &:after {
        background-color: var(--color-elements-primary);
      }
    }
  }
`;
