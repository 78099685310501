import { useMemo } from 'react';
import { Avatar as AvatarUI } from '@components';
import { getInitialLetters, getAvatarColorsByLetter } from '@functions';
import { Skeleton } from 'antd';
import { StyledDisplayName, StyledWrapper } from './index.styles';
import { useAvatar } from './useAvatar';

interface IAvatarProps {
  className?: string;
  size?: number;
  withoutName?: boolean;
}

export function Avatar({ className, size = 24, withoutName }: IAvatarProps) {
  let { fullName, isLoading } = useAvatar();

  let userAvatarMeta = useMemo(() => {
    let letter = getInitialLetters(fullName, 1) || '?';

    return {
      ...getAvatarColorsByLetter(letter),
      label: getInitialLetters(fullName),
    };
  }, [fullName]);

  if (isLoading) {
    return (
      <StyledWrapper style={{ lineHeight: 1 }}>
        <Skeleton.Avatar active size="small" />
        <Skeleton.Input active size="small" />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper className={className}>
      <AvatarUI
        size={24}
        label={userAvatarMeta.label}
        color={userAvatarMeta.initialColor}
        backgroundColor={userAvatarMeta.backgroundColor}
      />
      {!withoutName && <StyledDisplayName>{fullName}</StyledDisplayName>}
    </StyledWrapper>
  );
}
