import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { MenuItem } from '@entities';
import { ROUTES } from './routes';

export const ROUTES_ARR = [
  {
    key: 'users',
    route: ROUTES.users,
    activeKey: MenuItem.USERS,
    breadcrumbs: [
      {
        title: 'EZ Platform',
        route: '#',
      },
      {
        title: 'Users',
        route: '#',
      },
    ],
  },
  {
    key: 'userProfile',
    route: ROUTES.userProfile,
    activeKey: MenuItem.USERS,
    breadcrumbs: [
      {
        title: 'EZ Platform',
        route: '#',
      },
      {
        title: 'Users',
        route: '#',
      },
      {
        title: 'User Details',
        route: '#',
      },
    ],
  },
  {
    key: 'clients',
    route: ROUTES.clients,
    activeKey: MenuItem.CLIENTS,
    breadcrumbs: [
      {
        title: 'EZ Platform',
        route: '#',
      },
      {
        title: 'Clients',
        route: '#',
      },
    ],
  },
  {
    key: 'clientProfile',
    route: ROUTES.clientProfile,
    activeKey: MenuItem.CLIENTS,
    breadcrumbs: [
      {
        title: 'EZ Platform',
        route: '#',
      },
      {
        title: 'Clients',
        route: '#',
      },
      {
        title: 'Client Details',
        route: '#',
      },
    ],
  },
  {
    key: 'accounts',
    route: ROUTES.accounts,
    activeKey: MenuItem.ACCOUNTS,
    breadcrumbs: [
      {
        title: 'EZ Platform',
        route: '#',
      },
      {
        title: 'Accounts',
        route: '#',
      },
    ],
  },
  {
    key: 'accountProfile',
    route: ROUTES.accountProfile,
    activeKey: MenuItem.ACCOUNTS,
    breadcrumbs: [
      {
        title: 'EZ Platform',
        route: '#',
      },
      {
        title: 'Accounts',
        route: '#',
      },
      {
        title: 'Account Details',
        route: '#',
      },
    ],
  },
  {
    key: 'transactions',
    route: ROUTES.transactions,
    activeKey: MenuItem.TRANSACTIONS,
    breadcrumbs: [
      {
        title: 'EZ Platform',
        route: '#',
      },
      {
        title: 'Transactions',
        route: '#',
      },
    ],
  },
  {
    key: 'transactionProfile',
    route: ROUTES.transactionProfile,
    activeKey: MenuItem.TRANSACTIONS,
    breadcrumbs: [
      {
        title: 'EZ Platform',
        route: '#',
      },
      {
        title: 'Transactions',
        route: '#',
      },
      {
        title: 'Transaction Details',
        route: '#',
      },
    ],
  },
  {
    key: 'accounts',
    route: ROUTES.accounts,
    activeKey: MenuItem.ACCOUNTS,
    breadcrumbs: [
      {
        title: 'EZ Backoffice',
        route: '#',
      },
      {
        title: 'Accounts',
        route: '#',
      },
    ],
  },
  {
    key: 'accountProfile',
    route: ROUTES.accountProfile,
    activeKey: MenuItem.ACCOUNT_PROFILE,
    breadcrumbs: [
      {
        title: 'EZ Backoffice',
        route: '#',
      },
      {
        title: 'Accounts',
        route: '#',
      },
      {
        title: 'Account Details',
        route: '#',
      },
    ],
  },
  {
    key: 'transactions',
    route: ROUTES.transactions,
    activeKey: MenuItem.TRANSACTIONS,
    breadcrumbs: [
      {
        title: 'EZ Backoffice',
        route: '#',
      },
      {
        title: 'Transactions',
        route: '#',
      },
    ],
  },
  {
    key: 'transactionProfile',
    route: ROUTES.transactionProfile,
    activeKey: MenuItem.TRANSACTION_PROFILE,
    breadcrumbs: [
      {
        title: 'EZ Backoffice',
        route: '#',
      },
      {
        title: 'Transactions',
        route: '#',
      },
      {
        title: 'Transaction Details',
        route: '#',
      },
    ],
  },
  {
    key: 'ez-roles',
    route: ROUTES.ezRoles,
    activeKey: MenuItem.SETTINGS,
    breadcrumbs: [
      {
        title: 'EZ Backoffice',
        route: '#',
      },
      {
        title: 'Roles',
        route: '#',
      },
    ],
  },
  {
    key: 'ez-users',
    route: ROUTES.ezUsers,
    activeKey: MenuItem.SETTINGS,
    breadcrumbs: [
      {
        title: 'EZ Backoffice',
        route: '#',
      },
      {
        title: 'Users',
        route: '#',
      },
    ],
  },
  {
    key: 'invites',
    route: ROUTES.ezInvites,
    activeKey: MenuItem.SETTINGS,
    breadcrumbs: [
      {
        title: 'EZ Backoffice',
        route: '#',
      },
      {
        title: 'User Invites',
        route: '#',
      },
    ],
  },
  {
    key: 'invitesProfile',
    route: ROUTES.ezInviteProfile,
    activeKey: MenuItem.SETTINGS,
    breadcrumbs: [
      {
        title: 'EZ Backoffice',
        route: '#',
      },
      {
        title: 'User Invites',
        route: '#',
      },
      {
        title: 'Invite Profile',
        route: '#',
      },
    ],
  },
];

export function useRoutes() {
  let location = useLocation();
  let currentRoute = useMemo(() => {
    return ROUTES_ARR.find((route) =>
      matchPath(route.route, location.pathname),
    );
  }, [location.pathname]);

  return {
    currentRoute,
  };
}
