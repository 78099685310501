import styled from '@emotion/styled';
import { Table as DefaultTable } from 'antd';
import { css } from '@emotion/react';

interface ITableStyleProps {
  pagination?: { total?: number };
}

let PaginationTextStyles = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
  color: var(--color-text-additional);
`;

export let Table = styled(DefaultTable)<ITableStyleProps>`
  &&& {
    .ant-table {
      border-radius: 6px;
      border: 1px solid var(--color-background-additional-main);
    }

    .ant-table-container table > thead > tr:first-child > *:first-child {
      border-start-start-radius: 5px;
    }

    .ant-table-container table > thead > tr:first-child > *:last-child {
      border-start-end-radius: 5px;
    }

    .ant-table-tbody .ant-table-cell {
      padding: 8px 8px 8px 16px;
      font-size: 12px;
      line-height: 1.83;
      color: var(--color-text-primary-2);

      border-bottom: ${({ pagination }) =>
        !Boolean(pagination?.total) && '1px solid transparent !important'};
      border-radius: ${({ pagination }) =>
        !Boolean(pagination?.total) && '6px'};
    }

    .ant-table-content {
      border-radius: ${({ pagination }) =>
        Boolean(pagination?.total) ? '6px 6px 0 0' : '6px'};
      border-top: 1px solid var(--color-background-additional-3);
      border-left: 1px solid var(--color-background-additional-3);
      border-right: 1px solid var(--color-background-additional-3);
      border-bottom: ${({ pagination }) =>
        !Boolean(pagination?.total) &&
        '1px solid var(--color-background-additional-3)'};
    }

    .ant-table-tbody > tr:last-child > td:hover {
      border-bottom: 1px solid var(--color-background-additional);
    }

    .ant-table-column-sorter span {
      color: var(--color-elements-additional);
    }

    .ant-table-column-sorter span .active {
      color: var(--color-elements-primary);
    }

    th {
      background-color: var(--color-background-additional-main);
      padding: 9px 0px 9px 16px !important;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-text-main-elements);

      & .ant-table-column-sorters {
        display: inline-flex;
        gap: 4px;
      }

      &:not(:last-of-type) {
        &:after {
          content: '';
          width: 1px;
          background-color: var(--color-elements-table-divider);
          position: absolute;
          height: calc(100% - 18px);
          top: 9px;
          right: 0;
        }
      }

      ::before {
        display: none;
      }
    }

    .ant-table-pagination {
      margin: 0 0 16px 0;
      padding: 8px 16px;
      background-color: var(--color-background-additional-main);
      border-radius: 0 0 6px 6px;
      text-align: end;

      ${PaginationTextStyles};

      .ant-select-selection-item,
      .ant-pagination-item a {
        ${PaginationTextStyles};
        color: var(--color-text-primary);
      }

      .ant-pagination-item-active {
        background-color: var(--color-button-primary);
        border-radius: 4px;
        border: 1px solid transparent;

        a {
          font-weight: 500;
          color: var(--color-text-additional);
        }
      }

      .ant-pagination-item-link,
      .ant-select-arrow,
      .ant-pagination-item-link-icon {
        color: var(--color-elements-primary);
      }

      .ant-pagination-disabled .ant-pagination-item-link {
        color: var(--color-button-disable-2);
      }

      .ant-select-selector {
        border-color: var(--color-elements-primary);
        border-radius: 4px;
      }
      .ant-pagination-options-quick-jumper input {
        border-color: var(--color-field-default-stroke);
        border-radius: 4px;
      }
    }
  }
`;
