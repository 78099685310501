import { insertIfObj, normalizeStatus } from '@functions';
import {
  ClientDraftState,
  ClientKind,
  ClientState,
  ClientUserState,
} from '@entities';

export let clientKindMap = {
  [ClientKind.PERSONAL]: 'Individual',
  [ClientKind.BUSINESS]: 'Business',
};

export let clientKindColorMap = {
  [ClientKind.PERSONAL]: 'var(--color-elements-additional-13)',
  [ClientKind.BUSINESS]: 'var(--color-elements-additional-12)',
};

export let clientKindFontColorMap = {
  [ClientKind.PERSONAL]: 'var(--color-elements-additional-14)',
  [ClientKind.BUSINESS]: 'var(--color-elements-additional-3)',
};

export let clientUserStateColorMap = {
  [ClientUserState.ACTIVE]: 'var(--color-status-success)',
  [ClientUserState.BLOCKED]: 'var(--color-status-error)',
  [ClientUserState.REJECTED]: '',
  [ClientUserState.CREATED]: '',
};

export let clientUserStateFontColorMap = {
  [ClientUserState.ACTIVE]: 'var(--color-text-success)',
  [ClientUserState.BLOCKED]: 'var(--color-text-error)',
  [ClientUserState.REJECTED]: '',
  [ClientUserState.CREATED]: '',
};

export let clientStateColorMap = {
  [ClientState.ACTIVE]: 'var(--color-status-success)',
  [ClientState.CLOSED]: 'var(--color-status-error)',
  [ClientState.INACTIVE]: 'var(--color-status-error)',
  [ClientState.PRE_ACTIVATED]: 'var()',
  [ClientState.APPROVED]: 'var()',
  [ClientState.IN_REVIEW]: 'var()',
  [ClientState.REJECTED]: 'var()',
  [ClientState.ON_HOLD]: 'var()',
  [ClientState.BLOCKED]: 'var()',
  [ClientState.CREATED]: 'var()',
};

export let clientStateFontColorMap = {
  [ClientState.ACTIVE]: 'var(--color-text-success)',
  [ClientState.CLOSED]: 'var(--color-text-error)',
  [ClientState.INACTIVE]: 'var(--color-text-error)',
  [ClientState.PRE_ACTIVATED]: 'var()',
  [ClientState.APPROVED]: 'var()',
  [ClientState.IN_REVIEW]: 'var()',
  [ClientState.REJECTED]: 'var()',
  [ClientState.ON_HOLD]: 'var()',
  [ClientState.BLOCKED]: 'var()',
  [ClientState.CREATED]: 'var()',
};

export let clientDraftStateColorMap = {
  [ClientDraftState.IN_REVIEW]: 'var()',
  [ClientDraftState.PRE_ACTIVATED]: 'var()',
  [ClientDraftState.CREATED]: 'var()',
  [ClientDraftState.FORM_REQUESTED]: 'var()',
  [ClientDraftState.APPROVED]: 'var()',
  [ClientDraftState.SIGNED]: 'var()',
  [ClientDraftState.CLIENT_CREATED]: 'var()',
  [ClientDraftState.REJECTED]: 'var()',
  [ClientDraftState.WAITED]: 'var()',
  [ClientDraftState.ARCHIVED]: 'var()',
};

export function getClientTypeByKind(kind?: ClientKind) {
  if (kind) {
    return {
      displayName: clientKindMap[kind],
      color: clientKindColorMap[kind],
      fontColor: clientKindFontColorMap[kind],
    };
  }

  return {
    displayName: '-',
    color: '',
    fontColor: '',
  };
}

export function getClientStatus(status?: ClientUserState) {
  if (status) {
    return {
      color: clientUserStateColorMap[status],
      fontColor: clientUserStateFontColorMap[status],
    };
  }

  return {
    color: '',
    fontColor: '',
  };
}

export function getClientStatusByState({
  client,
  draft,
}: {
  client: ClientState;
  draft: ClientDraftState;
}) {
  let preparedDraft = {
    color: clientDraftStateColorMap[draft] ?? 'var(--color-status-error)',
    fontColor: clientDraftStateColorMap[draft] ?? 'var(--color-text-error)',
    displayName: normalizeStatus(draft),
  };

  return {
    ...insertIfObj(
      ![
        ClientState.PRE_ACTIVATED,
        ClientState.CREATED,
        ClientState.IN_REVIEW,
      ].includes(client),
      {
        client: {
          color: clientStateColorMap[client] ?? 'var(--color-status-error)',
          fontColor:
            clientStateFontColorMap[client] ?? 'var(--color-text-error)',
          displayName: normalizeStatus(client),
        },
      },
    ),
    ...insertIfObj(
      [
        ClientState.PRE_ACTIVATED,
        ClientState.CREATED,
        ClientState.IN_REVIEW,
      ].includes(client),
      {
        draft: preparedDraft,
      },
    ),
  };
}
