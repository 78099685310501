import { AxiosInstance } from 'axios';
import * as client from './client';
import * as baseApi from './baseApi';
import * as coreApi from './coreApi';
import * as platformApi from './platformApi';

export interface IBaseApiRequest {
  api: AxiosInstance;
}

export interface IFilterByCustomer {
  baasClientId: string;
}

export let api = {
  client,
  baseApi,
  coreApi,
  platformApi,
};
