import { ISortable } from '@entities';
import { insertIfObj } from './insertIf';
import { convertCamelToSnakeCase } from './convertSnackToCamel';

export function getSorterParamsForApi({ attribute, direction }: ISortable) {
  return {
    ...insertIfObj(Boolean(attribute), {
      sort_by: convertCamelToSnakeCase(attribute),
    }),
    ...insertIfObj(Boolean(direction), {
      sort_direction: direction,
    }),
  };
}
