import { useState, useCallback } from 'react';

export interface IUseModalController {
  isOpen: boolean;
  toggle: () => void;
  dismiss: () => void;
  open: () => void;
}

export function useModalController() {
  let [isOpen, setIsOpen] = useState(false);
  let [meta, setMeta] = useState<any>(null);
  let toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  let dismiss = useCallback(() => setIsOpen(false), [setIsOpen]);
  let open = useCallback(() => setIsOpen(true), [setIsOpen]);
  return {
    isOpen,
    meta,
    setMeta,
    toggle,
    dismiss,
    open,
  };
}
