import { Tag } from 'antd';
import styled from '@emotion/styled';
import { ITagProps } from '.';

export let TagWrapper = styled.div`
  display: flex;
`;

export let StyledTag = styled(Tag)<ITagProps>`
  &&& {
    margin-inline-end: 0;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    color: ${({ textColor }) => textColor};
  }
`;
