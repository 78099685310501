import { AxiosResponse } from 'axios';
import { IBaseApiRequest } from '@api';
import {
  IDirectoryItem,
  IDirectoryItemsFilters,
  IPaginated,
  PaginationMeta,
} from '@entities';
import { getSorterParamsForApi } from '@functions';

interface IGetAll extends IBaseApiRequest, IDirectoryItemsFilters, IPaginated {
  attribute?: string;
  direction?: string;
}
export async function getAll({
  api,
  offset,
  limit,
  attribute,
  direction,
}: IGetAll) {
  let params = {
    ...getSorterParamsForApi({ attribute, direction }),
    offset,
    limit,
  };

  let response = (await api.get('directory_items/currency', {
    params,
  })) as AxiosResponse<{
    data: { data: IDirectoryItem[] };
    meta: PaginationMeta;
  }>;

  return response.data.data.data;
}
