import { ClientKind, IClientShort } from './Client';

export enum ClientUserState {
  CREATED = 'created',
  ACTIVE = 'active',
  REJECTED = 'rejected',
  BLOCKED = 'blocked',
}

export enum ClientUserRole {
  IS_ADMIN = 'isAdmin',
  IS_OWNER = 'isOwner',
  IS_CLIENT_ROLE = 'isClientRole',
}

export interface IClientUsersFilters {
  search?: string;
  clientType?: ClientKind | '' | null;
  teammateRole?: ClientUserRole | null;
  teammateStatus?: string | null;
}

export interface IClientUser {
  id: string;
  activatedAt: string;
  aidCustomerId: string;
  aidProfileId: string;
  createdAt: number;
  firstAccountCreatedAt: number | null;
  isAdmin: boolean;
  isOwner: boolean;
  may: {
    reject: boolean;
    block: boolean;
    activate: boolean;
    addAidVerification: boolean;
  };
  onboardingFacts: {};
  rejectionReason: string;
  role: {
    id: string;
    clientId: string;
    description: string;
    displayName: string;
    abilities: Array<{
      id: string;
      code: string;
      displayName: string;
      group: string;
      kind: string;
    }>;

    isAdmin: boolean;
    isOwner: boolean;
    roleAccounts: Array<{
      abilities: Array<{
        code: string;
        displayName: string;
        group: string;
        id: string;
        kind: string;
      }>;
      clientAccount: { id: string; accountNo: string; displayName: string };
      id: string;
      limits: { expenseDay: number; expenseWeek: number; expenseMonth: number };
      roleId: string;
    }>;
    roleId: string;
    roleIntegrationsAccounts: [];
    roleKind: string;
    userId: string;
  };
  state: ClientUserState;
  updatedAt: number;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
  };
  client: IClientShort;
}

export interface IClientUserLimit {
  accountNo: string;
  currency: {
    id: string;
    displayName: string;
    kind: string;
    symbol: string;
    value: string;
  };
  stat?: {
    expenseDay: string;
    expenseWeek: string;
    expenseMonth: string;
  };
  limits?: {
    expenseDay: number;
    expenseWeek: number;
    expenseMonth: number;
  };
}
