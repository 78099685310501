import { useState, useEffect, useMemo } from 'react';
import { fromEvent } from 'rxjs';
import { share, map, debounceTime } from 'rxjs/operators';

function getSize() {
  return { width: window.innerWidth, height: window.innerHeight };
}

let windowResize$ = fromEvent(window, 'resize').pipe(
  debounceTime(200),
  map(getSize),
  share(),
);

export function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize);

  let isTablet = useMemo(() => windowSize.width <= 1100, [windowSize.width]);

  useEffect(() => {
    let sub = windowResize$.subscribe(setWindowSize);

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return { isTablet, ...windowSize };
}
