import styled from '@emotion/styled';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';

export let FieldWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

export let StyledCopyOutlined = styled(CopyOutlined)`
  margin-left: 8px;
  color: var(--color-elements-additional);
  animation-duration: 2s;
  animation-name: appearance;

  @keyframes appearance {
    from {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

export let StyledCheckOutlined = styled(CheckOutlined)`
  margin-left: 8px;
  color: var(--color-elements-additional);
  animation-duration: 2s;
  animation-name: appearance;

  @keyframes appearance {
    from {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;
