const NUMBER_WORD: { [key: string]: string } = {
  '1': 'A',
  '2': 'B',
  '3': 'C',
  '4': 'D',
  '5': 'E',
  '6': 'F',
  '7': 'G',
  '8': 'H',
  '9': 'I',
  '0': 'J',
};

function replaceNumbersToWords(caption: string) {
  return caption
    .split('')
    .map((char) => NUMBER_WORD[char] || char)
    .join('');
}

export function getInitialLetters(caption: string, count: number = 2) {
  let words = replaceNumbersToWords(caption)
    .toUpperCase()
    .replace(/[^a-zA-Z]+/g, ' ')
    .replace(/  +/g, ' ')
    .split(' ')
    .filter((item) => Boolean(item.trim()));

  let preparedWords = words.length > 1 ? words : (words?.[0] ?? '').split('');
  let initials = preparedWords
    .map((word) => word?.[0])
    .filter((_, index) => index <= count - 1)
    .join('');

  return initials.length < count ? initials.repeat(count) : initials;
}

