import { LoadableButton } from '@components';
import {
  StyledExclamationCircleOutlinedIcon,
  StyledMinimalModalTitleWrapper,
  StyledMinimalModal,
  StyledMinimalModalTitle,
} from '../elements.styles';
import { useLogoutConfirmationLogic } from './useLogoutConfirmationLogic';

export interface ILogoutConfirmationProps {
  open: boolean;
  onClose: () => void;
}

export function LogoutConfirmation({
  open,
  onClose,
}: ILogoutConfirmationProps) {
  let { isLoading, handleLogout } = useLogoutConfirmationLogic();

  return (
    <StyledMinimalModal
      open={open}
      onCancel={onClose}
      footer={
        <>
          <LoadableButton buttonType="primary" onClick={onClose}>
            No
          </LoadableButton>
          <LoadableButton
            buttonType="danger"
            isLoading={isLoading}
            onClick={handleLogout}
          >
            Yes
          </LoadableButton>
        </>
      }
    >
      <StyledMinimalModalTitleWrapper>
        <StyledExclamationCircleOutlinedIcon />
        <StyledMinimalModalTitle>
          Are you sure that you want to log out?
        </StyledMinimalModalTitle>
      </StyledMinimalModalTitleWrapper>
    </StyledMinimalModal>
  );
}
