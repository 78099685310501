export function navigateToLogin() {
  window.location.replace(
    `${import.meta.env.VITE_API_URL}login?redirect_url=${window.location.href}`,
  );
}

export function navigateToLogout() {
  window.location.replace(
    `${import.meta.env.VITE_API_URL}logout?redirect_url=${
      window.location.href
    }`,
  );
}
