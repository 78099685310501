import { IBaseApiRequest } from '@api';
import { AxiosResponse } from 'axios';
import { IPaginated } from '@entities';

interface IGetAll extends IBaseApiRequest, IPaginated {
  subjectType: string;
}
export async function getAll({ api, subjectType, offset, limit }: IGetAll) {
  let response = (await api.post('filters', {
    subjectType,
    offset,
    limit,
  })) as AxiosResponse<{
    data: {
      data: Array<{ key: string; values: string[] }>;
    };
  }>;

  return response.data?.data?.data;
}
