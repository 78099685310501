import { CSSProperties, PropsWithChildren, forwardRef } from 'react';
import { StyledText, ITextStyleProps } from './index.styles';

export enum TextTag {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  p = 'p',
  small = 'small',
  span = 'span',
  div = 'div',
  label = 'label',
  strong = 'strong',
}

export interface ITextProps extends ITextStyleProps {
  as?: TextTag;
  onClick?: () => void;
  style?: CSSProperties;
}

export let Text = forwardRef<
  HTMLParagraphElement,
  PropsWithChildren<ITextProps>
>(
  (
    {
      as = TextTag.p,
      size = 16,
      weight = 500,
      wrap = true,
      children,
      ...props
    },
    forwardRef,
  ) => {
    return (
      <StyledText
        as={as}
        ref={forwardRef}
        size={size}
        weight={weight}
        wrap={wrap}
        {...props}
      >
        {children}
      </StyledText>
    );
  },
);
