import React, { ReactNode } from 'react';
import { css, Global } from '@emotion/react';

export let colorVariables = css`
  :root {
    --color-background-primary: #ffffff;
    --color-background-secondary: #fafafa;
    --color-background-additional-light: #f9f0ff;
    --color-background-additional-dark: #efdbff;
    --color-background-additional-main: #f0f0f0;

    --color-field-default-bg: #ffffff;
    --color-field-disabled-bg: #f5f5f5;
    --color-field-default-stroke: #d9d9d9;
    --color-field-filled-stroke: #9254de;
    --color-field-filter-placeholder: #8c8c8c;
    --color-field-primary: #141414;
    --color-field-title: #120338;

    --color-button-primary: #efdbff;
    --color-button-secondary: #ffffff;
    --color-button-primary-text-icon: #22075e;
    --color-button-primary-stroke: #d3adf7;
    --color-button-error-text: #5c0011;
    --color-button-error: #ffccc7;
    --color-button-success: #cff1e1;
    --color-button-disable: #f5f5f5;
    --color-button-disable-text: #bfbfbf;

    --color-status-default: #f0f0f0;
    --color-status-success: #e6fffb;
    --color-status-error: #fff1f0;
    --color-status-processing: #fff7e6;
    --color-status-warning: #d46b08;

    --color-text-primary: #22075e;
    --color-text-secondary: #262626;
    --color-text-additional: #141414;
    --color-text-success: #092b00;
    --color-text-error: #a8071a;
    --color-text-error-1: #cf1322;
    --color-text-processing: #ad4e00;
    --color-text-main-elements: #120338;
    --color-text-gray-primary: #595959;
    --color-text-gray-secondary: #8c8c8c;

    --color-elements-primary: #722ed1;
    --color-elements-secondary: #d9d9d9;
    --color-elements-additional: #d3adf7;
    --color-elements-additional-2: #120338;
    --color-elements-additional-3: #22075e;
    --color-elements-additional-4: #d6e4ff;
    --color-elements-additional-5: #061178;
    --color-elements-additional-8: #1d39c4;
    --color-elements-additional-9: #ffe7ba;
    --color-elements-additional-10: #ad4e00;
    --color-elements-additional-12: #e6f7ff;
    --color-elements-additional-13: #fff2e8;
    --color-elements-additional-14: #610b00;
    --color-elements-table-divider: #ffffff;

    --color-tag-primary: #22075e;
    --color-tag-secondary: #597ef7;

    --color-modal-close: #b37feb;

    --color-scroll-background: #f5f5f5;
    --color-scroll-thumb: #888888;

    --color-volcano-2: #ffd8bf;
    --color-volcano-9: #871400;

    --color-polar-green: #135200;

    --color-neutral-4: #f0f0f0;

    --color-status-success-2: #135200;
    --color-status-error-2: #a8071a;
  }
`;

export function ColorsProvider({ children }: { children: ReactNode }) {
  return (
    <>
      <Global styles={colorVariables} />
      {children}
    </>
  );
}
