import styled from '@emotion/styled';
import { Typography } from 'antd';
import { LoadableButton } from '@components';

export let StyledHeadSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-additional-main);
  padding: 4px 16px;
`;

export let StyledTitle = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  color: var(--color-text-primary-1);
`;

export let StyledButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
`;

export let StyledLoadableButton = styled(LoadableButton)`
  &&& {
    font-size: 12px;

    span {
      line-height: 1;
    }
  }
`;
