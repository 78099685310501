import styled from '@emotion/styled';

export let StyledAction = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 6px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-button-secondary);
  flex-shrink: 0;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;
