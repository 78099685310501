import styled from '@emotion/styled';
import { Typography } from 'antd';
import { LoadableButton } from '@components';

interface IButtonsWrapperStyleProps {
  buttonsWidth?: string;
}

export let StyledWrapper = styled.div``;

export let StyledSection = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 16px;
  margin-top: 8px;
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-neutral-4);
  border-radius: 6px;
  padding: 8px 16px;

  @media (max-width: 800px) {
    grid-auto-flow: row;
    grid-row-gap: 10px;
  }
`;

export let ButtonsWrapper = styled.div<IButtonsWrapperStyleProps>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: ${({ buttonsWidth }) => buttonsWidth || '180px'};
  grid-column-gap: 12px;
`;

export let StyledSectionTitle = styled(Typography.Text)<{ isDanger?: boolean }>`
  &&& {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    color: ${({ isDanger }) =>
      isDanger ? ' var(--color-text-error)' : ' var(--color-text-primary-1)'};
  }
`;

export let StyledSectionDescription = styled(Typography.Text)`
  &&& {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    grid-gap: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.71;
    color: var(--color-text-primary-1);
  }
`;

export let StyledPrimaryAction = styled(LoadableButton)`
  &&& {
    min-height: 32px;
    height: auto;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
    }
  }
`;
