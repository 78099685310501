import styled from '@emotion/styled';
import { Typography } from 'antd';

interface IStyledWrapperProps {
  hasActions?: boolean;
}

export let StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export let StyledWrapper = styled.div<IStyledWrapperProps>`
  border: 1px solid var(--color-background-additional-main);
  border-radius: ${({ hasActions }) => (hasActions ? '6px 6px 0 0 ' : '6px')};
  overflow: hidden;
`;

export let StyledTitle = styled(Typography.Text)`
  &&& {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    color: var(--color-text-primary-2);
  }
`;
