import styled from '@emotion/styled';
import { Typography } from 'antd';
import { Col, Input, LoadableButton, Row, Select } from '@components';

export let StyledFilterSection = styled.div`
  margin: 16px 0 12px 0;
  padding: 12px 16px;
  background-color: var(--color-background-additional-main);
  border: 1px solid var(--color-background-additional-main);
  border-radius: 6px;
`;

export let StyledFilterRow = styled(Row)`
  &:not(:first-of-type) {
    margin-top: 12px;
  }
`;

export let StyledFilter = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export let StyledFilterTitle = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.83;
    color: var(--color-field-primary);
  }
`;

export let StyledFilterInput = styled(Input)`
  &&& {
    min-height: 32px;
  }
`;

export let StyledFilterSelect = styled(Select)``;

export let StyledButton = styled(LoadableButton)`
  &&& {
    width: 100%;
    padding: 3px 15px;
    height: 32px;

    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.83;
    }
  }
`;

export let Filter = {
  Section: StyledFilterSection,
  Row: StyledFilterRow,
  Field: StyledFilter,
  Title: StyledFilterTitle,
  Input: StyledFilterInput,
  Select: StyledFilterSelect,
  Button: StyledButton,
};
