import styled from '@emotion/styled';
import { Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { LoadableButton } from '@components';

export let StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export let TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 13px auto;
  grid-column-gap: 12px;
  align-items: center;
`;

export let StyledTitle = styled(Typography.Text)`
  &&& {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.75;
    color: var(--color-text-primary);
  }
`;

export let StyledBackIcon = styled(ArrowLeftOutlined)`
  &&& {
    cursor: pointer;
    color: var(--color-elements-primary);
    font-size: 13px;
  }
`;

export let StyledLoadableButton = styled(LoadableButton)`
  &&& {
    min-width: 180px;
  }
`;
