import { IBaseApiRequest } from '@api';
import {
  IBapiUser,
  IInvite,
  IInvitesFilters,
  IPaginated,
  ISortable,
  PaginationMeta,
} from '@entities';
import { getSorterParamsForApi, insertIfObj } from '@functions';
import { AxiosResponse } from 'axios';

interface IGetAll
  extends IBaseApiRequest,
    IPaginated,
    ISortable,
    IInvitesFilters {
  baasClientId: string;
}
export async function getAll({
  api,
  offset,
  limit,
  attribute,
  direction,
  search,
  status,
  baasClientId,
}: IGetAll) {
  let params = {
    offset,
    limit,
    ...insertIfObj(Boolean(status), {
      state: status,
    }),
    ...insertIfObj(Boolean(search), {
      search,
    }),
    ...getSorterParamsForApi({ attribute, direction }),
  };

  let response = (await api.get(`platform/${baasClientId}/invites`, {
    params,
  })) as AxiosResponse<{
    data: {
      data: IInvite[];
      meta: PaginationMeta;
    };
  }>;

  return response.data.data;
}

interface ICreate extends IBaseApiRequest {
  baasClientId: string;
  dto: {
    firstName: string;
    lastName: string;
    email: string;
    baasRoleId: string;
  };
}
export async function create({ api, dto, baasClientId }: ICreate) {
  try {
    let response = (await api.post(
      `platform/${baasClientId}/invite`,
      dto,
    )) as AxiosResponse<{
      data: IInvite;
    }>;

    return {
      success: true,
      result: {},
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.message,
    };
  }
}

interface IGetOne extends IBaseApiRequest {
  id: string;
  baasClientId: string;
}
export async function getOne({ api, id, baasClientId }: IGetOne) {
  let response = (await api.get(
    `platform/${baasClientId}/invites/${id}`,
  )) as AxiosResponse<{
    data: IInvite;
  }>;

  return response.data.data;
}

interface IRevoke extends IBaseApiRequest {
  id: string;
  baasClientId: string;
}
export async function revoke({ api, id, baasClientId }: IRevoke) {
  try {
    let response = (await api.delete(
      `platform/${baasClientId}/invite/${id}`,
    )) as AxiosResponse<{
      data: IInvite;
    }>;

    return {
      success: true,
      result: {},
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error,
    };
  }
}

interface IResend extends IBaseApiRequest {
  id: string;
  baasClientId: string;
}
export async function resend({ api, id, baasClientId }: IResend) {
  try {
    let response = (await api.post(
      `platform/${baasClientId}/invites/${id}/resend`,
    )) as AxiosResponse<{
      data: IInvite;
    }>;

    return {
      success: true,
      result: {},
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error,
    };
  }
}
