import React, { PropsWithChildren, Suspense, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined, LogoutOutlined, RightOutlined } from '@ant-design/icons';
import { css, Global } from '@emotion/react';
import { ROUTES } from '@router';
import { useCurrentClient, useModalController } from '@hooks';
import { LogoutConfirmation } from '@modals';
import { DefaultOptionType } from 'antd/es/select';
import { Select } from '../Select';
import {
  ContentLayoutContainer,
  StyledAvatar,
  StyledCollapsedMenuHeader,
  StyledCollapseElement,
  StyledContent,
  StyledContentLayout,
  StyledHeader,
  StyledIconWrapper,
  StyledInnerLayout,
  StyledLayout,
  StyledLeftMenu,
  StyledLeftSider,
  StyledLogo,
  StyledLogoWithTitle,
  StyledLogoTitle,
  StyledMenuItem,
  StyledSubMenuItem,
  StyledTopMenu,
  StyledEnvironment,
  StyledLeftPart,
} from './index.styles';
import { DynamicBreadcrumb } from './DynamicBreadcrumb';
import { LocalLoader } from './LocalLoader';
import { useSiderItems } from './useSiderItems';

let globalScrollStyles = css`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--color-scroll-background);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll-thumb);
    border-radius: 4px;
  }
`;

export function ProtectedLayout({ children }: PropsWithChildren<unknown>) {
  let { currentClient, allClients, onChange } = useCurrentClient();
  let [collapsed, setCollapsed] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let logoutConfirmationModalController = useModalController();
  let { siderMenuItems, active, defaultSelectedKeys } = useSiderItems();
  let isWindows = /Win/i.test(navigator.userAgent);

  let renderedMenu = useMemo(() => {
    return (
      <StyledLeftMenu
        getPopupContainer={(node) => node.parentNode as HTMLElement}
        mode={collapsed ? 'vertical' : 'inline'}
        activeKey={active}
        defaultOpenKeys={defaultSelectedKeys}
        selectedKeys={defaultSelectedKeys}
      >
        {siderMenuItems.map((section) => {
          if (section?.children) {
            return (
              <StyledSubMenuItem
                isActive={String(active).includes(section.key)}
                collapsed={collapsed}
                title={section.label}
                icon={section.icon}
                key={section.key}
              >
                {collapsed && (
                  <StyledCollapsedMenuHeader title={section.label}>
                    {section.label}
                  </StyledCollapsedMenuHeader>
                )}
                {section.children.map((item) => (
                  <StyledMenuItem
                    isActive={item.key === active}
                    collapsed={collapsed}
                    title={item.label}
                    key={item.key}
                  >
                    <Link to={item?.to ?? ''}>{item.label}</Link>
                  </StyledMenuItem>
                ))}
              </StyledSubMenuItem>
            );
          }

          return (
            <StyledMenuItem
              isSingle
              isActive={section.key === active}
              collapsed={collapsed}
              title={section.label}
              key={section.key}
              icon={section.icon}
            >
              <Link to={section?.to ?? ''}>{section.label}</Link>
            </StyledMenuItem>
          );
        })}
      </StyledLeftMenu>
    );
  }, [collapsed, active, defaultSelectedKeys, siderMenuItems]);

  let renderedClientSelect = useMemo(() => {
    let options: DefaultOptionType[] | undefined = allClients.map((client) => ({
      label: client.displayName,
      value: client.id,
    }));

    return (
      <Select
        value={currentClient?.id}
        options={options}
        style={{ width: 200 }}
        size="small"
        placeholder="Choose customer"
        onChange={onChange}
        showSearch={false}
      />
    );
  }, [allClients, currentClient?.id, onChange]);

  return (
    <StyledLayout>
      <Global styles={isWindows ? globalScrollStyles : ''} />
      <StyledHeader>
        <StyledLeftPart>
          <StyledLogoWithTitle>
            <StyledLogo onClick={() => navigate(ROUTES.users)} />
            <StyledLogoTitle>PLATFORM</StyledLogoTitle>
          </StyledLogoWithTitle>
          <StyledEnvironment>
            {String(import.meta.env.VITE_CONFIG_ENV ?? '').toUpperCase()}
          </StyledEnvironment>
        </StyledLeftPart>
        <StyledTopMenu>
          {renderedClientSelect}
          <StyledAvatar />
          <StyledIconWrapper onClick={logoutConfirmationModalController.open}>
            <LogoutOutlined />
          </StyledIconWrapper>
        </StyledTopMenu>
      </StyledHeader>
      <StyledInnerLayout>
        <StyledLeftSider
          collapsed={collapsed}
          onCollapse={() => setCollapsed((prev) => !prev)}
        >
          {renderedMenu}
          <StyledCollapseElement onClick={() => setCollapsed((prev) => !prev)}>
            {collapsed ? <RightOutlined /> : <LeftOutlined />}
          </StyledCollapseElement>
        </StyledLeftSider>
        <StyledContentLayout>
          <ContentLayoutContainer>
            <DynamicBreadcrumb route={location.pathname} />
            <StyledContent>
              <Suspense
                fallback={<LocalLoader description="Loading local page" />}
              >
                {children}
              </Suspense>
            </StyledContent>
          </ContentLayoutContainer>
        </StyledContentLayout>
      </StyledInnerLayout>
      {logoutConfirmationModalController.isOpen && (
        <LogoutConfirmation
          open={logoutConfirmationModalController.isOpen}
          onClose={logoutConfirmationModalController.dismiss}
        />
      )}
    </StyledLayout>
  );
}
