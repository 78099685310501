import { PropsWithChildren, Suspense } from 'react';
import { FullScreenSpin } from './FullScreenSpin';

export function GlobalSuspense({ children }: PropsWithChildren<unknown>) {
  // return (<FullScreenSpin description="Loading your application..." />)

  return (
    <Suspense
      fallback={<FullScreenSpin description="Loading your application..." />}
    >
      {children}
    </Suspense>
  );

  // return <Suspense fallback="loading">{children}</Suspense>;
}
