import { IBaseApiRequest } from '@api';
import { IInvite, IPaginated, ISortable } from '@entities';
import { getSorterParamsForApi, insertIfObj } from '@functions';
import { AxiosResponse } from 'axios';
import { IContact } from '../../entities/Contact';

interface IGetAll extends IBaseApiRequest, IPaginated, ISortable {
  baasClientId: string;
  clientId?: string;
}
export async function getAll({
  api,
  offset,
  limit,
  attribute,
  direction,
  baasClientId,
  clientId,
}: IGetAll) {
  let params = {
    offset,
    limit,
    ...insertIfObj(Boolean(clientId), {
      clientId,
    }),
    ...getSorterParamsForApi({ attribute, direction }),
  };

  let response = (await api.get(
    // `bank/${'24b7b8be-5614-48c8-9697-193fca10c9b5'}/contacts`,
    `bank/${baasClientId}/contacts`,
    {
      params,
    },
  )) as AxiosResponse<{
    success: boolean;
    errorCode: number;
    error: string;
    message: string;
    data: {
      data: IContact[];
      meta: {
        offset: number;
        limit: number;
        total: number;
      };
    };
  }>;

  return response.data.data;
}

interface IGetOne extends IBaseApiRequest {
  id: string;
  baasClientId: string;
}
export async function getOne({ api, id, baasClientId }: IGetOne) {
  let response = (await api.get(
    `bank/${baasClientId}/contacts/${id}`,
  )) as AxiosResponse<{
    data: IContact;
  }>;

  return response.data.data;
}
