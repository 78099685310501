import React from 'react';
import { useRoutes } from '../../../router/useRoutes';
import {
  StyledBreadcrumb,
  StyledBreadcrumbItem,
  StyledBreadcrumbSeparator,
} from './index.styles';

export interface IDynamicBreadcrumbProps {
  route: string;
}

export function DynamicBreadcrumb({ route }: IDynamicBreadcrumbProps) {
  let { currentRoute } = useRoutes();

  return (
    <StyledBreadcrumb
      separator={<StyledBreadcrumbSeparator>&gt;</StyledBreadcrumbSeparator>}
    >
      {currentRoute?.breadcrumbs.map((item, index, arr) => {
        if (item.title) {
          return (
            <StyledBreadcrumbItem
              last={arr.length - 1 === index}
              key={item.title.concat(item.route)}
            >
              {item.title}
            </StyledBreadcrumbItem>
          );
        }

        return <></>;
      })}
    </StyledBreadcrumb>
  );
}
