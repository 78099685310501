import styled from '@emotion/styled';
import { LoadableButton } from '@components';

export let StyledButton = styled(LoadableButton)`
  &&& {
    width: 100%;
    height: 32px;
    padding: 3px 15px;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.83;
    }
  }
`;
