import {
  ReactChild,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';

let UpdatedAtContext = createContext<[Dayjs, (d: Dayjs) => void]>([
  dayjs(),
  () => {},
]);

export function UpdatedAtController({ children }: { children: ReactChild }) {
  let [d, st] = useState(dayjs());
  let r = useCallback(() => st(dayjs()), [st]);
  useEffect(() => {
    let id = setTimeout(r, 15000);
    return () => {
      clearTimeout(id);
    };
  }, [d, r]);
  return (
    <UpdatedAtContext.Provider value={[d, r]}>
      {children}
    </UpdatedAtContext.Provider>
  );
}

export function useLastUpdatedAt() {
  let [d, r] = useContext(UpdatedAtContext);
  return [d, r];
}
