import styled from '@emotion/styled';
import { InputNumber } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';

interface INumberRangeStylesProps {
  isEmpty?: boolean;
}

export let StyledRangeWrapper = styled.div<INumberRangeStylesProps>`
  display: grid;
  grid-template-columns: 1fr 32px 1fr;
  align-items: center;
  background-color: #ffffff;

  border: 1px solid
    ${({ isEmpty }) =>
      isEmpty
        ? 'var(--color-field-filter-placeholder)'
        : 'var(--color-field-primary)'};
  border-radius: 6px;
`;

export let StyledInputNumber = styled(InputNumber)`
  &&& {
    width: 100%;
    border-color: transparent;
    box-shadow: none;
    color: var(--color-text-primary-1);

    .ant-input-number-handler {
      border-color: var(--color-field-filter-placeholder);
    }

    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      color: var(--color-field-filter-placeholder);
    }

    input {
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.83;
      color: var(--color-field-primary);

      ::placeholder {
        color: var(--color-field-filter-placeholder);
      }
    }
  }
`;

export let StyledRangeDivider = styled(
  SwapRightOutlined,
)<INumberRangeStylesProps>`
  &&& {
    font-size: 16px;
    color: ${({ isEmpty }) =>
      isEmpty
        ? 'var(--color-field-filter-placeholder)'
        : 'var(--color-field-primary)'};
  }
`;
