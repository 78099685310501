import { useCallback, useState } from 'react';
import { navigateToLogout } from '../../providers/AxiosClientProvider/navigateToLogin';

export function useLogoutConfirmationLogic() {
  let [isLoading, setLoading] = useState(false);

  let handleLogout = useCallback(() => {
    setLoading(true);
    navigateToLogout();
  }, []);

  return {
    isLoading,
    handleLogout,
  };
}
