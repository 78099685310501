import styled from '@emotion/styled';
import { Input, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Input as CustomInput, Select } from '@components';

export let StyledMinimalModal = styled(Modal)`
  &&& {
    .ant-modal-content {
      padding: 24px 32px;
    }

    .ant-modal-footer {
      margin-top: 24px;
      padding: 0;
      background: transparent;
      box-shadow: none;
    }

    .ant-modal-body {
      padding: 0;
    }
  }
`;

export let StyledMinimalModalTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-column-gap: 16px;
`;

export let StyledMinimalModalTitle = styled(Typography.Paragraph)`
  &&& {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    color: var(--color-text-primary);
  }
`;

export let StyledExclamationCircleOutlinedIcon = styled(
  ExclamationCircleOutlined,
)`
  &&& {
    font-size: 22px;
    color: var(--color-status-warning);
  }
`;

export let StyledSelect = styled(Select)``;

export let StyledTextArea = styled(Input.TextArea)`
  &&& {
    margin-top: 4px;
    border: 1px solid var(--color-background-additional-5);
    border-radius: 6px;
  }
`;

export let StyledFieldWrapper = styled.div`
  display: grid;
  grid-row-gap: 4px;
`;

export let StyledFieldTitle = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.83;
    color: var(--color-text-primary);
  }
`;

export let StyledInput = styled(CustomInput)``;
