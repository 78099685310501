import styled from '@emotion/styled';
import { Row } from 'antd';

export let StyledRow = styled(Row)`
  align-items: end;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: start;
  }
`;
