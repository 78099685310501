import { forwardRef } from 'react';
import { StyledImage } from './index.styles';

export interface ImageProps extends React.HTMLAttributes<HTMLImageElement> {
  ref?: React.Ref<HTMLImageElement>;
  alt?: string;
  src?: string;
  width?: string;
  height?: string;
}

export let Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ alt = 'Arival Bank International Corp.', ...props }, forwardRef) => {
    return <StyledImage alt={alt} ref={forwardRef} {...props} />;
  },
);
