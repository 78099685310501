import axios, { AxiosInstance } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

export function createAxiosClient(apiUrl: string) {
  const options = {
    preservedKeys: [],
  };

  return applyCaseMiddleware(
    axios.create({
      baseURL: apiUrl,
      withCredentials: true,
    }),
    options,
  );
}

export function setAuthTokenToHeader(
  api: AxiosInstance,
  token?: string | undefined,
) {
  if (token) {
    return (api.defaults.headers.common['Authorization'] = `Bearer ${token}`);
  }

  api.defaults.headers.common['Authorization'] = '';
}
