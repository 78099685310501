import React, { ReactNode, useMemo } from 'react';
import {
  AppstoreOutlined,
  CreditCardOutlined,
  HistoryOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { ROUTES } from '@router';
import { MenuItem } from '@entities';
import { useRoutes } from '../../router/useRoutes';

interface IMenuUnifiedMethod {
  key: string;
  icon?: ReactNode;
  label: string;
  to?: string;
  children?: Array<{
    key: string;
    label: string;
    to: string;
    enabled: boolean;
  }>;
  enabled: boolean;
}

export function useSiderItems() {
  let { currentRoute } = useRoutes();
  let defaultSelectedKeys = useMemo(() => {
    let active = currentRoute?.activeKey ?? '';

    return [active.split('.')?.[0], active];
  }, [currentRoute?.activeKey]);

  let siderMenuItems: Array<IMenuUnifiedMethod> = useMemo(() => {
    return [
      {
        key: MenuItem.USERS,
        icon: <TeamOutlined />,
        label: 'Users',
        to: ROUTES.users,
        enabled: true,
      },
      {
        key: MenuItem.CLIENTS,
        icon: <AppstoreOutlined />,
        label: 'Clients',
        to: ROUTES.clients,
        enabled: true,
      },
      {
        key: MenuItem.ACCOUNTS,
        icon: <CreditCardOutlined />,
        label: 'Accounts',
        to: ROUTES.accounts,
        enabled: true,
      },
      {
        key: MenuItem.TRANSACTIONS,
        icon: <HistoryOutlined />,
        label: 'Transactions',
        to: ROUTES.transactions,
        enabled: true,
      },
      {
        key: MenuItem.SETTINGS,
        icon: <SettingOutlined />,
        label: 'EZ Settings',
        enabled: true,
        children: [
          {
            key: MenuItem.SETTINGS_USERS,
            label: 'Users',
            to: ROUTES.ezUsers,
            enabled: true,
          },
          {
            key: MenuItem.SETTINGS_ROLES,
            label: 'Roles',
            to: ROUTES.ezRoles,
            enabled: true,
          },
          {
            key: MenuItem.SETTINGS_INVITES,
            label: 'Invites',
            to: ROUTES.ezInvites,
            enabled: true,
          },
        ],
      },
    ].filter((item) => item.enabled);
  }, []);

  return {
    siderMenuItems,
    active: currentRoute?.activeKey,
    defaultSelectedKeys,
  };
}
