import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Image } from './Image';
import { Text } from './Text';

let sizeToFont: Record<AvatarSizeT, SerializedStyles> = {
  24: css`
    font-size: 9px;
    line-height: 12px;
    font-weight: 600;
  `,
  32: css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  `,
};

export type AvatarSizeT = 24 | 32;

interface IAvatarSharedStyleProps {
  size: AvatarSizeT;
}

interface IAvatarRootStyleProps extends IAvatarSharedStyleProps {
  didImageFailToLoad: boolean;
  backgroundColor?: string;
  disabled?: boolean;
}

interface IAvatarImageStyleProps extends IAvatarSharedStyleProps {
  imageLoaded: boolean;
}

interface ILabelStyleProps {
  avatarSize: AvatarSizeT;
  disabled?: boolean;
}

function getBackground(
  didImageFailToLoad: boolean,
  backgroundColor?: string,
  disabled?: boolean,
) {
  if (didImageFailToLoad) {
    return backgroundColor;
  }
  return 'none';
}

export let AvatarRoot = styled.div<IAvatarRootStyleProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  // Image previously set the root width/height
  // Since image is not rendered, set the height/width
  width: ${({ didImageFailToLoad, size }) =>
    didImageFailToLoad ? `${size}px` : null};
  height: ${({ didImageFailToLoad, size }) =>
    didImageFailToLoad ? `${size}px` : null};
  border-radius: 50%;
  background: ${({ didImageFailToLoad, backgroundColor, disabled }) =>
    getBackground(didImageFailToLoad, backgroundColor, disabled)};
  box-sizing: border-box;
`;

export let AvatarImage = styled(Image)<IAvatarImageStyleProps>`
  display: ${({ imageLoaded }) => (imageLoaded ? 'block' : 'none')};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  object-fit: cover;
  box-sizing: border-box;
`;

export let Label = styled(Text)<ILabelStyleProps>`
  ${({ avatarSize }) => sizeToFont[avatarSize]};
`;

export let BadgeWrapper = styled.div`
  position: absolute;
`;

export let BottomBadgeWrapper = styled(BadgeWrapper)`
  bottom: 0;
  right: -7px;
`;
