import useSWR from 'swr';
import { useApi } from './useApi';

export function useCurrentUser() {
  let { api, client } = useApi();
  let {
    data,
    error,
    isValidating: isLoading,
  } = useSWR('auth/me', async () =>
    api.baseApi.auth.getMe({
      api: client,
    }),
  );

  return {
    data,
    error,
    isLoading,
  };
}
